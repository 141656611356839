// extracted by mini-css-extract-plugin
export var active = "contact-module--active--9hl3R";
export var bgLight = "contact-module--bg-light--9L6B0";
export var brandedTitle = "contact-module--branded-title--FTwtu";
export var buttonBrand = "contact-module--button-brand--ynqRR";
export var columns = "contact-module--columns--yuZXJ";
export var contactSection = "contact-module--contact-section--OGXs-";
export var contactform = "contact-module--contactform--HBB0x";
export var fEmail = "contact-module--f-email--4Hm6M";
export var fMessage = "contact-module--f-message--PcrSs";
export var fName = "contact-module--f-name--cYUs+";
export var feedbackInput = "contact-module--feedback-input--FTC51";
export var focused = "contact-module--focused--mrM0y";
export var formDiv = "contact-module--form-div--WIdj8";
export var formMain = "contact-module--form-main--vwia2";
export var formMessage = "contact-module--form-message--0ADJo";
export var marginTop15rem = "contact-module--margin-top-1-5rem--NuYXm";
export var openForm = "contact-module--openForm--8xCZI";
export var paddingBottom1rem = "contact-module--padding-bottom-1rem--mMtNE";
export var paddingTop1rem = "contact-module--padding-top-1rem--yLtaP";
export var social = "contact-module--social--x20ZK";